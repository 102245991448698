@media screen and (max-width: 991px) {
  .name-section {
    text-align: center;
    margin-top: 1rem;
  }
}

.icon-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 0.5rem;
}

@media screen and (max-width: 991px) {
  .icon-section {
    justify-content: center;
  }
}

.name-description {
  font-size: 14px;
  color: #6c757d;
}
