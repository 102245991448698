@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary-color: #ffc651;
$primary-color-darken: #f0a100;

.App {
  text-align: center;
}

.custom-container {
  width: 860px;
  margin: auto;
  flex-wrap: inherit;
}
@media screen and (max-width: 992px) {
  .custom-container {
    width: 90%;
  }
}

@media screen and (min-width: 992px) {
  .custom-section {
    margin-top: 1rem;
  }
}

body {
  background-color: rgba($primary-color, 0.57);
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 40px;
}
h2 {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 600;
  padding: 0.4rem 0;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  padding: 0.4rem 0;
}
h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding: 0.4rem 0;
}

.custom-link {
  color: inherit;
  font-weight: 500;
  box-shadow: inset 0 -10px 0 rgba($primary-color, 0.4);
  text-decoration: none;
  padding: 0 4px;
}
.custom-link:hover {
  filter: brightness(85%);
}

a.nav-link,
a.navbar-brand {
  color: var(--bs-nav-link-color);
}

a.iconLink {
  color: inherit;
}
a.iconLink:hover {
  filter: brightness(85%);
}
