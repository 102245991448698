div.pub-entry {
  padding: 1rem 0;
  border-bottom: 0.5px solid #cccccc;
}

.pub-title {
  margin-bottom: 5px;
}
.pub-title a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.light .pub-title a {
  color: black;
}
.dark .pub-title a {
  color: white;
}

.pub-name a {
  color: inherit;
  text-decoration: none;
}
.pub-title a:hover,
.pub-name a:hover {
  text-decoration: wavy underline #ffc651;
}

div.pub-name,
div.pub-detail {
  font-size: 14px;
  font-weight: 400;
}

div.pub-venue {
  width: 120px;
  text-align: right;
}
@media screen and (max-width: 991px) {
  div.pub-venue {
    width: 100%;
    text-align: left;
  }
}

span.my-name {
  font-weight: 700;
  text-decoration: wavy underline #ffc651;
}

.pub-detail a {
  color: inherit;
}

.pub-award .pub-award {
  font-size: 0.8rem;
  font-weight: 500;
  background-color: var(--primary-color-dark);
  color: black;
  margin-right: 5px;
  margin-top: 5px;
}
